import { FC, Fragment } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import {
  IntegrationAnalysisIncomingEvent,
  INTEGRATION_ANALYSIS_WEBHOOK_DELIVERY_STATUS,
  INTEGRATION_ANALYSIS_WEBHOOK_TOPIC,
} from '@signifyd/http'
import {
  getEventDisplayName,
  getWebhookTopicDisplayName,
  groupWebhooks,
  isApiEvent,
  isV3Webhook,
  isWebhookEvent,
  SelectedEvent,
  WebhookEventSummary,
} from 'core/utils/integrationEvents/utils'
import styles from './ApiEvents.less'

interface Props {
  checkoutId?: string
  events?: Array<SelectedEvent>
  selectedEvent: SelectedEvent
  setSelectedEvent: (selectedEvent: SelectedEvent) => void
}

const regexMatchAfterUnderscore = /(?<=_)/g

const ApiEventItem: FC<{
  event: IntegrationAnalysisIncomingEvent
  selectedEvent: SelectedEvent
  setSelectedEvent: (selectedEvent: SelectedEvent) => void
}> = ({ event, selectedEvent, setSelectedEvent }) => {
  const { t } = useTranslation()

  const hasMissingFields = event.fieldAnalyses.length
  const isSelected =
    isApiEvent(selectedEvent) && selectedEvent.eventName === event.eventName

  return (
    <button
      className={cx(
        styles.fieldQuality,
        hasMissingFields ? styles.missing : styles.present,
        isSelected && styles.selected
      )}
      data-test-id={`${event.eventName}ApiEvent`}
      onClick={() => setSelectedEvent(event)}
      type="button"
    >
      <Text
        className={styles.textColor}
        size="sm"
        weight={isSelected ? 'semibold' : 'normal'}
      >
        {t(
          `jsonViewerPage.dataQuality.${
            event.fieldAnalyses.length ? 'missingFields' : 'allFieldsPresent'
          }.label`,
          {
            count: event.fieldAnalyses.length,
          }
        )}
      </Text>
    </button>
  )
}

const WebhookEventItem: FC<{
  selectedEvent: SelectedEvent
  setSelectedEvent: (selectedEvent: SelectedEvent) => void
  webhookSummary: WebhookEventSummary
}> = ({ selectedEvent, setSelectedEvent, webhookSummary }) => {
  const { t } = useTranslation()

  const isSelected =
    isWebhookEvent(selectedEvent) &&
    selectedEvent.webhookTopic === webhookSummary?.webhookTopic &&
    selectedEvent.lastWebhook.webhookId === webhookSummary.lastWebhook.webhookId

  const isDeliverySuccess =
    webhookSummary.lastWebhook.deliveryStatus ===
    INTEGRATION_ANALYSIS_WEBHOOK_DELIVERY_STATUS.SUCCESS

  const displayName = isV3Webhook(webhookSummary)
    ? t('jsonViewerPage.dataQuality.webhookV3Display', {
        number: webhookSummary.destinationUrlIndex + 1,
      })
    : t('jsonViewerPage.dataQuality.webhookV2Display')

  return (
    <button
      className={cx(
        styles.fieldQuality,
        isDeliverySuccess ? styles.present : styles.missing,
        isSelected && styles.selected
      )}
      data-test-id={`${webhookSummary.webhookTopic}-${webhookSummary.destinationUrlIndex}-Webhook`}
      onClick={() => setSelectedEvent(webhookSummary)}
      type="button"
    >
      <Text
        block
        className={styles.webhookTitle}
        size="sm"
        weight={isSelected ? 'semibold' : 'normal'}
      >
        {displayName}
      </Text>
      <Text block className={styles.webhookUrl} size="sm">
        {webhookSummary.destinationUrl}
      </Text>
    </button>
  )
}

const ApiEvents: FC<Props> = ({
  checkoutId,
  events,
  selectedEvent,
  setSelectedEvent,
}) => {
  const { t } = useTranslation()

  if (!events?.length) {
    return null
  }

  const apiEvents = events.filter(isApiEvent)
  const webhookEvents = events.filter(isWebhookEvent)
  const groupedWebhooks = groupWebhooks(webhookEvents)

  return (
    <div className={styles.apiEvents}>
      {checkoutId && (
        <Text className={cx(styles.checkoutId, styles.textColor)} size="xs">
          {t('jsonViewerPage.checkoutId', { checkoutId })}
        </Text>
      )}
      {apiEvents.map((event) => (
        <Fragment key={event.eventName}>
          <div className={styles.apiHeader} data-test-id="eventType">
            <Text className={styles.textColor} size="sm">
              {getEventDisplayName(event)}
            </Text>
          </div>
          <ApiEventItem
            event={event}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
          />
        </Fragment>
      ))}
      {!!webhookEvents.length && (
        <>
          <div className={styles.apiHeader} data-test-id="eventType">
            <Text className={styles.textColor} size="sm">
              {t('jsonViewerPage.webhooks')}
            </Text>
          </div>
          {Object.keys(groupedWebhooks).map((webhookTopic) => (
            <Fragment key={webhookTopic}>
              <Text
                className={cx(styles.webhookTopic, styles.textColor)}
                size="sm"
              >
                {getWebhookTopicDisplayName(
                  webhookTopic as INTEGRATION_ANALYSIS_WEBHOOK_TOPIC
                )
                  ?.split(regexMatchAfterUnderscore)
                  .map((x) => (
                    <Fragment key={x}>
                      {x}
                      <wbr />
                    </Fragment>
                  ))}
              </Text>
              {groupedWebhooks[
                webhookTopic as INTEGRATION_ANALYSIS_WEBHOOK_TOPIC
              ].map((webhookSummary) => (
                <Fragment key={webhookSummary.destinationUrl}>
                  <WebhookEventItem
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    webhookSummary={webhookSummary}
                  />
                </Fragment>
              ))}
            </Fragment>
          ))}
        </>
      )}
    </div>
  )
}

export default ApiEvents
